import React from 'react'
import NotFoundImage from '../assets/404error.jpg'
import styles from './NotFound.module.css'

function NotFound() {
	return (
		<div className={styles.container}>
			<img src={NotFoundImage} alt='404 Not Found' className={styles.image} />
		</div>
	)
}

export default NotFound
