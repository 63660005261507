import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'
import styled from 'styled-components'

import NotFound from './components/NotFound'
import Intro from './pages/Intro'

const AppContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 100vh;
`

function App() {
	return (
		<Router>
			<AppContainer>
				<Routes>
					<Route path='/' element={<Intro />} />
					<Route path='*' element={<NotFound />} />
				</Routes>
			</AppContainer>
		</Router>
	)
}

export default App
