import React, { useEffect } from 'react'
import EstateImg from '../assets/EstateImg.png'
import EmailIcon from '../assets/email-icon.png'
import PhoneIcon from '../assets/phone-icon.png'
import ScrollDown from '../assets/scrollDown.png'
import TelegramIcon from '../assets/telegram-icon.png'
import WhatsappIcon from '../assets/whatsapp-icon.png'
import styles from './Intro.module.css'

function Intro() {
	useEffect(() => {
		const handleScroll = () => {
			const scrollIcon = document.querySelector(`.${styles.scrollIcon}`)
			if (window.scrollY === 0) {
				scrollIcon.style.animationPlayState = 'running'
			} else {
				scrollIcon.style.animationPlayState = 'paused'
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [])

	return (
		<main className={styles.container}>
			<img
				loading='lazy'
				src={EstateImg}
				className={styles.backgroundImage}
				alt='Estate background'
			/>
			<section className={styles.content}>
				<h1 className={styles.title}>
					<span>OZNO</span>
					<span>ESTATE</span>
				</h1>
				<p className={styles.scrollText}>COMING SOON</p>
				<img
					loading='lazy'
					src={ScrollDown}
					className={styles.scrollIcon}
					alt='Scroll down icon'
				/>
			</section>
			<div className={styles.contactInfo}>
				<div className={styles.contactRow}>
					<img src={EmailIcon} alt='Email Icon' className={styles.icon} />
					<span>info@ozno.estate</span>
				</div>
				<div className={styles.contactRow}>
					<img src={PhoneIcon} alt='Phone Icon' className={styles.icon} />
					<span>+90 (531) 913 51 99</span>
				</div>
				<div className={styles.contactRow}>
					<a
						href='https://t.me/OznoEstate'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={TelegramIcon}
							alt='Telegram Icon'
							className={styles.icon}
						/>
					</a>
					<a
						href='https://wa.me/905319135199'
						target='_blank'
						rel='noopener noreferrer'
					>
						<img
							src={WhatsappIcon}
							alt='Whatsapp Icon'
							className={styles.icon}
						/>
					</a>
				</div>
			</div>
		</main>
	)
}

export default Intro
